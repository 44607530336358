.input-list__container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    padding-left: 20px;
}

.input-list__container option {
    height: auto;
    overflow: hidden;
    min-height: 1.1876em;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.list-section :first-child {
    margin: 1em 0 2em 0;
}

.input-list__form-control {
    border: 0;
    display: inline-flex;
    padding: 0;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
    margin: 0 0 4px;
}

.input-list__form-control .full-width {
    width: 100%;
}

.input-list__container h6 {
    font-size: 1.25rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    margin-bottom: 4px !important;
}

.input-list__container .body {
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}

.list-section {
    margin: 2em 0;
}

.list-section :first-child {
    margin: 1em 0 2em 0;
}

.input-list__preview {
    width: 150px;
    margin: 0.5em 0;
}
