.button-base--root {
    color: inherit;
    border: 0;
    cursor: pointer;
    margin: 0;
    display: inline-flex;
    outline: 0;
    padding: 0;
    position: relative;
    align-items: center;
    user-select: none;
    border-radius: 0;
    vertical-align: middle;
    -moz-appearance: none;
    justify-content: center;
    text-decoration: none;
    background-color: transparent;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.fab--root {
    color: rgba(0, 0, 0, 0.87);
    width: 56px;
    height: 56px;
    padding: 0;
    font-size: 0.875rem;
    min-width: 0;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
    box-sizing: border-box;
    min-height: 36px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 50%;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    background-color: #e0e0e0;
}

.fab--small {
    width: 40px;
    height: 40px;
}

.fab__label {
    width: 100%;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
}

.fab {
    margin-right: 8px;
    box-sizing: border-box;
    box-shadow: none;
    border: 1px solid #565656
}

.fab--enabled {
    border: 1px solid #565656;
    opacity: 0.16;
}

.fab--disabled {
    border: 1px solid;
}

.fab__cursor--enabled {
    cursor: pointer;
}

.fab__cursor--disabled {
    cursor: not-allowed;
}
